import React from 'react';
import Multani from '../../assets/images/multani.png';
import PRESTIGE from '../../assets/images/PRESTIGE.png';
import TVS from '../../assets/images/TVS.png';
import VMARC from '../../assets/images/V-MARC.png';
import PatilPvt from '../../assets/images/patilPvt.png';
import './integration.scss';

const Integrations: React.FC = () => {
  const imgList = [
    { img: PatilPvt },
    { img: PatilPvt },
    { img: VMARC },
    { img: Multani },
    { img: TVS },
    { img: PRESTIGE },
  ]
  return (
    <div className='container'>
      <h2 className="integration-title">Integrations</h2>
      <div className='integrations-div'>
        {imgList.map((item) => (
          <img src={item.img} className='integrations-img' />
        ))}
      </div>
    </div>
  );
}
export default Integrations;
