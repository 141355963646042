import React, { FC } from 'react';
import './Button.scss';
import loading from '../../assets/images/loading.gif';

// Define the props interface
interface ButtonProps {
    button: string;
    type?: 'button' | 'submit' | 'reset'; // Restricting type to valid button types
    onClick?: () => void;
    onSubmit?: () => void;
    isLoading?: boolean;
    disabled?: boolean;
    link?: string;
    target?: '_self' | '_blank'; // Defines if the link opens in the same tab or a new one
}

const Button: FC<ButtonProps> = ({
    button,
    type = 'button', // Default to 'button' if not provided
    onClick,
    onSubmit,
    isLoading = false, // Default to false
    disabled = false, // Default to false
    link,
    target = '_self', // Default to '_self' if not provided
}) => {
    const handleClick = () => {
        if (link && target === "_blank") {
            window.open(link, '_blank');
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <button
            type={type}
            className="btnAll"
            onSubmit={onSubmit}
            onClick={handleClick}
            disabled={disabled}
        >
            {isLoading ? (
                <i>
                    <div className="lds-dual-ring"></div>
                </i>
            ) : (
                <i className="icon-rightArrow" />
            )}
            <span className="button-text">{button}</span>
        </button>
    );
};

export default Button;
