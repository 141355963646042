import React, { useEffect, useState } from "react";
import LoginImg from "../../assets/images/login.png";
import "./login.scss";
import { Card, Container, FormCheck } from "react-bootstrap";
import Button from "../../components/Button/Button";
import TextField from "../../components/TextField/TextField";
import HookForm from "../../components/HookForm/hookForm";
import Constant from "../../util/constant";
import { loginData } from "../../features/auth/loginSlice";
import { AppDispatch } from "../../app/store";
import { OK } from "../../config/httpStatusCodes";
import { setSession } from "../../config/localStorage";
import { setUserCredentials } from "../../util/utils";
import { useAppDispatch } from "../../app/hook";
import { showError, showSuccess } from "../../helpers/messageHelper";
import { useNavigate } from "react-router-dom";
import { fetchFrontImage } from "../../features/auth/fetchFrontImageSlice";

const Login: React.FC = () => {
  const [frontImage, setFrontImage] = useState<string>("");

  const dispatch = useAppDispatch();

  const imageHeaderStyle = {
    backgroundImage: `url(${LoginImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "425px",
    width: "100%",
  };

  const loginForm = {
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: "Please enter valid email",
        },
      },
    },
    password: {
      name: "password",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };

  const [remember, setRemember] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImage = async () => {
      const fetchImageAction = fetchFrontImage("login");
      const { payload } = await (dispatch as AppDispatch)(fetchImageAction);
      if (payload.status === OK) {
        setFrontImage(payload.data.responseData.image_url);
      }
    };

    // fetchImage();
    // setRemember(true);
  }, []);

  const onSubmit = async (data: any) => {
    // const loginAction = loginData(data);
    // const { payload } = await (dispatch as AppDispatch)(loginAction);

    // if (payload && payload.status === OK && payload.data.responseData) {
    //   const { responseData, responseMessage } = payload.data;
    //   remember
    //     ? setUserCredentials(JSON.stringify(data))
    //     : localStorage.removeItem("rememberMe");
    //   showSuccess(responseMessage);
    //   navigate('/home');
    //   // window.location.replace(responseData.redirect_url);
    //   setSession(responseData.authorization_token);
    // } else {
    //   showError(payload.data.responseMessage);
    // }
  };

  return (
    <>
      <div className="image-header">
        {/* <img src={frontImage} alt="about" className='responsive-image' /> */}
        <img src={LoginImg} alt="about" className="responsive-image" />
      </div>
      <Container>
        <h2 className="login-heading">Login</h2>
        <p className="intro-text mb-4">
          To get started, you need to sign in here.
        </p>
        <div>
          <HookForm
            // defaultForm={defaultFormValues}
            onSubmit={(data) => onSubmit(data)}
          >
            {(formMethod) => {
              return (
                <>
                  <div className="form-style login-div">
                    <Card>
                      <div>
                        <TextField
                          label={"Email address"}
                          formMethod={formMethod}
                          rules={loginForm.email.validate}
                          name={loginForm.email.name}
                          errors={formMethod?.formState.errors}
                          type="email"
                          placeholder="i.e. john@mail.com"
                        />
                      </div>
                      <div className="text-end">
                        <a onClick={() => navigate("/forgot-password")}>
                          Forgot Password?
                        </a>
                      </div>
                      <div>
                        <TextField
                          label={"Password  "}
                          formMethod={formMethod}
                          rules={loginForm.password.validate}
                          name={loginForm.password.name}
                          errors={formMethod?.formState.errors}
                          type="password"
                          placeholder="**********"
                        />
                      </div>
                      <div className="checkbox-div">
                        <FormCheck type="checkbox" label="Remember me" />
                      </div>
                      <Button
                        button="Sign-in"
                        type="submit"
                        disabled={!formMethod?.formState.isValid}
                        isLoading={false}
                      />
                    </Card>
                  </div>
                </>
              );
            }}
          </HookForm>
          <div className="create-acc">
            <p>
              Don't have an account?{" "}
              <a onClick={() => navigate("/signUp")} href="#">
                Create an account
              </a>
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};
export default Login;
