import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './guarantees.scss';
import triangleImage from '../../assets/images/triangle.png'; // Adjust the path as needed

// Define a type for the data items
interface GuaranteeItem {
  icon: string;
  title: string;
  description: string;
}

const Guarantees: React.FC = () => {
  // Define the data array with proper types
  const data: GuaranteeItem[] = [
    { icon: 'Data-Security', title: "Data Security", description: "Ensuring peace of mind." },
    { icon: 'persons', title: "User-Friendly Interface", description: "No IT knowledge required." },
    { icon: 'Cost-Efficiency', title: "Cost Efficiency", description: "Reduce operational costs." },
    { icon: 'On-Premises-Storage', title: "On-Premises Storage", description: "Data control, on-premises storage." },
    { icon: 'Comprehensive-Analytics', title: "Comprehensive Analytics", description: "Powerful analytics, informed decisions." },
    { icon: 'Data-Loss-Protection', title: "Data Loss Protection", description: "Total data security and protection." },
    { icon: 'Unauthorized-Access-Prevention', title: "Unauthorized Access Prevention", description: "Secure data against unauthorized access." },
    { icon: 'book', title: "Audit Trail Logs", description: "Detailed audit logs for data tracking." },
    { icon: 'Data-Retrieval', title: "Data Retrieval", description: "Fast and simple data retrieval process." },
  ];

  return (
    <div className="bg-section">
      <div className='bg-bottom'>
        <Container>
          <h2 className="title">Guarantees</h2>
          <div>
            <div className="row">
              {data.map((benefit, index) => (
                <div key={index} className="col-md-4 benefit-item">
                  <div className="benefit-card d-flex flex-column align-items-center">
                    <div className="icon-container mb-3">
                      <span className={`icon-style icon-${benefit.icon}`}></span>
                    </div>
                    <h5 className="text-center gurantee-title">{benefit.title}</h5>
                    <p className="text-center gurantee-description">{benefit.description}</p>
                  </div>
                  {(index + 1) % 3 !== 0 && <div className="vertical-line"></div>}
                {index < 6 && <div className="horizontal-line"></div>}
                {index < 6 && (index + 1) % 3 !== 0 && (
                  <img src={triangleImage} alt="Triangle" className="triangle-image" />
                )}
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Guarantees;
