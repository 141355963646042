import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AboutImg from '../../assets/images/about.png';
import FeatureList from '../../components/featureList/featureList';
import Integrations from '../../components/Integrations/integrations';
import SmartData from '../../components/SmartData/smartData';
import './aboutUs.scss';
import { useAppDispatch } from '../../app/hook';
import { fetchFrontImage } from '../../features/auth/fetchFrontImageSlice';
import { AppDispatch } from '../../app/store';
import { OK } from '../../config/httpStatusCodes';

const AboutUs: React.FC = () => {

  const [frontImage, setFrontImage] = useState<string>('');

  console.log(frontImage);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchImage = async () => {
      const fetchImageAction = fetchFrontImage('about_us');
      const { payload } = await (dispatch as AppDispatch)(fetchImageAction);
      if (payload.status === OK) {
        setFrontImage(payload.data.responseData.image_url);
      }
    };

    // fetchImage();
  }, [dispatch]);

  const imageHeaderStyle = {
    backgroundImage: `url(${frontImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '425px',
    width: '100%',
  };
  return (
    <div className="aboutBg">
      <div className="image-header">
        {/* <img src={frontImage} alt="about" className='responsive-image' /> */}
        <img src={AboutImg} alt="about" className='responsive-image' />

      </div>
      <div className="bg-round-about">
        <div className='bg-db-about'>
        <div className='ctn'>
          <section>
            <Row className="text-center mb-5 about-title-margin">
              <Col>
                <h2>About Us</h2>
                <p className="intro-text">
                  Smart Data Manager, developed by Wizone IT Network India Pvt Ltd, is a subscription-based
                  software designed for seamless data management and business analytics. Tailored for SMBs
                  across India, it offers a user-friendly interface that simplifies IT tasks, allowing business
                  owners with limited technical expertise to efficiently manage their data.
                </p>
              </Col>
            </Row>
          </section>
        </div>
          <section className='container'>
            <div>
              <div className='about-div'>
                <div className='about-title'>Founded in 2014 </div>
                <div className='about-txt'>Over 10 years of experience in delivering comprehensive<br />  IT solutions.</div>
              </div>
              <div className='about-div'>
                <div className='about-title'>Serving 1200+ Clients </div>
                <div className='about-txt'>Technology | E-commerce | Healthcare| Manufacturing<br /> Industries.
                </div>
              </div>
              <div className='about-div'>
                <div className='about-title'>24x7 Support </div>
                <div className='about-txt'>Guaranteed assistance with a unique 55-minute<br /> resolution promise.
                </div>
              </div>
              <div className='about-div'>
                <div className='about-title'>100% On-Premises<br /> Data Control</div>
                <div className='about-txt'>Offering secure, local storage solutions for total data<br /> ownership.
                </div>
              </div>
              <div className='about-div'>
                <div className='about-title'>70% Faster<br /> Decision-Making</div>
                <div className='about-txt'>Achieve rapid business insights with advanced analytics<br /> tools.
                </div>
              </div>
            </div>
          </section>

          <section>
            <SmartData />
          </section>
          <section>
            <Integrations />
          </section>
          <section className='smart-section'>
            <FeatureList />
          </section>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
