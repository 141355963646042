import { Navigate } from "react-router-dom";
import AboutUs from "../pages/aboutUs/aboutUs";
import ContactUs from "../pages/contactUs/contactUs";
import Feature from "../pages/feature/feature";
import ForgotPassword from "../pages/forgotPassword/forgotPassword";
import Home from "../pages/home/home";
import Login from "../pages/login/login";
import PricingTable from "../pages/pricing/pricing";
import PrivacyPolicy from "../pages/privacyPolicy/privacyPolicy";
import SetPassword from "../pages/setPassword/setPassword";
import SignUp from "../pages/signUp/signUp";
import { getSession } from "../config/localStorage";

const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
    const token = getSession();
    if (token) {
      return <Navigate to="/login" replace />;
    }
    return <>{children}</>;
  };

const publicRoutes = [
    { path: "/", element:  <AuthWrapper><Home /></AuthWrapper> },
    { path: "/signup", element: <AuthWrapper><SignUp /></AuthWrapper> },
    { path: "/forgot-password", element: <AuthWrapper><ForgotPassword /></AuthWrapper> },
    { path: "/reset-password/:token", element:<AuthWrapper> <SetPassword /></AuthWrapper> },
    { path: "/login", element: <Login /> },
    { path: "/about", element: <AboutUs /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/contactus", element: <ContactUs /> },
    { path: "/feature", element: <Feature /> },
    { path: "/pricing", element: <PricingTable /> },
];

export {  publicRoutes };
