import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import HomeImg from '../../assets/images/homeBg.png';
import './home.scss';
import Photo from '../../assets/images/Photo.png';
import Guarantees from '../../components/Guarantees/guarantees';
import Benefits from '../../components/Benefits/benefits';
import Integrations from '../../components/Integrations/integrations';
import SmartData from '../../components/SmartData/smartData';
import PricingPlans, { Plan } from '../../components/PricingPlan/pricingPlan';
import { useAppDispatch } from '../../app/hook';
import { fetchFrontImage } from '../../features/auth/fetchFrontImageSlice';
import { AppDispatch } from '../../app/store';
import { OK } from '../../config/httpStatusCodes';
import { getPlans } from '../../features/subscription/getPlanSlice';
import { createSubscriptionData } from '../../features/subscription/createSubscriptionSlice';
import { useNavigate } from 'react-router-dom';
import { getSession } from '../../config/localStorage';
import Constant from '../../util/constant';
import { config } from '../../config/config';
import { staticPlanData } from '../../dummy/planRes';

const Home: React.FC = () => {
    const navigate = useNavigate();
    const [frontImage, setFrontImage] = useState<string>('');
    const [plans, setPlans] = useState<Plan[]>([]);
    console.log(frontImage);

  const dispatch = useAppDispatch();

  const fetchImage = async () => {
    const fetchImageAction = fetchFrontImage("home");
    const { payload } = await (dispatch as AppDispatch)(fetchImageAction);
    if (payload.status === OK) {
      setFrontImage(payload.data.responseData.image_url);
    }
  };

  const createSubscription = async (planId: string) => {
    if (!getSession()) {
      navigate("/");
      return;
    }
    const subscription = createSubscriptionData({ planId });
    const { payload } = await (dispatch as AppDispatch)(subscription);
    if (payload.status === OK) {
      subscriptionSuccess(payload.data.responseData);
    } else {
      console.error("Failed to create subscription");
    }
  };

  const subscriptionSuccess = (responseData: any) => {
    const { customerName, customerEmail, planType, subscriptionId } = responseData;
    console.log("Subscription created successfully", subscriptionId);
    const options = {
      key: config.razorpayKey,
      subscription_id: subscriptionId,
      name: "Smart Data Manager",
      description: `${planType} Subscription`,
      callback_url: `${config.apiBaseUrl}subscription-success`,
      redirect: true,
      prefill: {
        name: customerName,
        email: customerEmail,
      },
      theme: {
        color: "#F37254",
      },
    };

    const rzp: any = new (window as any).Razorpay(options);
    rzp.open();
  };

  const handleSelectPlan = (planId: string) => {
    createSubscription(planId);
  };
  const fetchPlans = async () => {
    const fetchPlansAction = getPlans();
    const { payload } = await (dispatch as AppDispatch)(fetchPlansAction);
    if (payload.status === OK) {
      setPlans(payload.data.responseData);
      console.log(payload.data.responseData);
    }
  };

  useEffect(() => {
    // fetchImage();
    // fetchPlans();
  }, [dispatch]);

  const imageHeaderStyle = {
    backgroundImage: `url(${frontImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "500px",
    width: "100%",
  };
  return (
    <>
      <div className="image-header-home">
        {/* <div style={imageHeaderStyle}></div> */}
        {/* Image */}
        <img src={HomeImg} alt="about" className="responsive-image" />
        {/* Text and Button Overlay */}
        <div className="overlay-content">
          <h1>
            Securing Your Business,
            <br /> 24/7 - Because Your
            <br /> Data is Priceless
          </h1>
          <p>
            Data Safety, Guaranteed - If we can’t secure
            <br /> your data, then nobody can!
          </p>
          <button className="overlay-button">Know More</button>
        </div>
      </div>
      <section>
        <Integrations />
      </section>
      <section>
        <Guarantees />
      </section>
      <section className="benefits-section">
        <Benefits />
      </section>
      <section>
        <Container>
          <div className="about-style">
            <div>
              <div>
                <h1 className="home-title">About Us</h1>
                <p>
                  Smart Data Manager, developed by Wizone IT Network India Pvt
                  Ltd, is a subscription-based
                  <br /> software designed for seamless data management and
                  business analytics.{" "}
                </p>
              </div>
              <div className="list-style-main">
                <div className="d-flex">
                  <span className="icons icon-Stack"></span>
                  <div>
                    <h6>Our software ensures that all data.</h6>
                    <p>
                      is securely stored on-premises, providing privacy and
                      compliance with organizational policies.
                    </p>
                  </div>
                </div>
                <hr />
                <div className="d-flex">
                  <span className="icons  icon-Cpu1"></span>
                  <div>
                    <h6>Serving a wide range of industries.</h6>
                    <p>
                      including technology, e-commerce, and healthcare, Smart
                      Data Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="img-Section">
              <img src={Photo} alt="feature" />
            </div>
          </div>
        </Container>
      </section>
      <section>
        <SmartData />
      </section>
      <section className="my-5">
        <PricingPlans plans={staticPlanData} onSelectPlan={handleSelectPlan} />
      </section>
    </>
  );
};
export default Home;
