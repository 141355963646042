import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { loginDataReducer } from "../features/auth/loginSlice";
import { signUpDataReducer } from "../features/auth/signUpSlice";
import { fetchFrontImageReducer } from "../features/auth/fetchFrontImageSlice";
import { contactUsDataReducer } from "../features/auth/contactUsSlice";
import { resetPasswordReducer } from "../features/auth/resetPasswordSlice";
import { forgotPasswordReducer } from "../features/auth/forgotPasswordSlice";
import { getPlansReducer } from "../features/subscription/getPlanSlice";
import { createSubscriptionData } from "../features/subscription/createSubscriptionSlice";
const store = configureStore({
  reducer: combineReducers({
    loginDataReducer: loginDataReducer,
    signUpDataReducer: signUpDataReducer,
    fetchFrontImageReducer: fetchFrontImageReducer,
    contactUsDataReducer: contactUsDataReducer,
    resetPasswordReducer: resetPasswordReducer,
    forgotPasswordReducer: forgotPasswordReducer,
    getPlansReducer: getPlansReducer,
    createSubscriptionData: createSubscriptionData

  }),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;