import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/header";

interface LayoutProps { }

function Layout(props: LayoutProps) {
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header/>
        <div className="main-content">
          <Outlet />
        </div>
        <Footer/>
      </div>
    </React.Fragment>
  );
}

export default Layout;
