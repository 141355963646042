import React from 'react';
import './planCard.scss';

// Define the props type for PlanCard
interface PlanCardProps {
    name: string;
    price: any;
    features: string[];
    buttonText: string;
    subtitle?: string; // subtitle is optional
    savings?: string;  // savings is optional
    isSelected: boolean;
    onSelect: () => void; // onSelect is a function
    onButtonClick: () => void;
}

const PlanCard: React.FC<PlanCardProps> = ({
    name,
    price,
    features,
    buttonText,
    subtitle,
    savings,
    isSelected,
    onSelect,
    onButtonClick
}) => {
    return (
        <div
            className={`plan-card ${isSelected ? 'selected' : ''}`}
            onClick={onSelect}
        >
            <h2 className='main-name'>{name}</h2>
            {subtitle && <div className="subtitle">{subtitle}</div>}
            <div className="price">
                <span className="currency">Rs.</span>
                <span className="amount">{price}</span>
            </div>
            {savings && <div className="savings">{savings}</div>}
            <ul className={`features ${isSelected ? 'selectedFeature' : ''}`}>
                {features.map((feature, index) => (
                    <div className='plan-list'>
                        <span className='rightIcon icon-Subtract'></span>
                        <li key={index}>{feature}</li>
                    </div>
                ))}
            <button className="cta-button" onClick={onButtonClick}>{buttonText}</button>
            </ul>
        </div>
    );
};

export default PlanCard;
