import React, { useEffect, useState } from 'react';
import HeaderLogo from '../../assets/images/Header.svg';
import { Navbar, Nav, Button, Container, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // If you want to add custom styles
import './header.scss';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Constant from '../../util/constant';
import ProfileIcon from '../../assets/images/profile.png';
import { clearSession, getSession } from '../../config/localStorage';

const Header: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [activeClass, setActiveClass] = useState<any>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setActiveClass(location.pathname);
    setIsLoggedIn(!!getSession());
  }, [location]);

  const handleLogout = () => {
    clearSession();
    setIsLoggedIn(false);
    navigate('/');
  };

  return (
    <div className='header'>
    <header className="container">
      <div className='main-Header py-2'>
        <div className="header-left">
          <div className="header-style">
            <a href="tel:+919258299518" className="text-phone">
            <span className="icon-telephone-1"></span>
            <div className='phone-style'> Call for Demo: <b>+91 92582 99518</b></div>
            </a>
            <div className='divider'></div>
            <a href="mailto:sales@smartdatamanager.in" className="text-email">
            <span className="icon-envelope-1"></span> 
            <div className='text-email'>Send email: <b>sales@smartdatamanager.in</b></div>
            </a>
          </div>
        </div>
        
        <div className="header-right">
          <div className="d-flex justify-content-end align-items-center">
          <div className='follow-us'>Follow Us:</div>
            <a href="https://facebook.com" className="text-dark me-3" target="_blank" rel="noopener noreferrer">
            <span className="icon-facebook"></span>
            </a>
            <a href="https://instagram.com" className="text-dark me-3" target="_blank" rel="noopener noreferrer">
            <span className="icon-instagram"></span>
            </a>
            <a href="https://twitter.com" className="text-dark me-3" target="_blank" rel="noopener noreferrer">
            <span className="icon-twitter"></span>
            </a>
            <a href="https://google.com" className="text-dark" target="_blank" rel="noopener noreferrer">
            <span className="icon-google"></span>
            </a>
          </div>
        </div>
      </div>



      <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="#home" className="d-flex align-items-center logo-style">
          <img
            src={HeaderLogo} // Replace with your logo path
            alt="Logo"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="m-auto">
            <Link to="/" className={activeClass === '/' ? 'activeMenu' : 'inactive'}>Home</Link>
            <Link to="/about"  className={activeClass === '/about' ? 'activeMenu' : 'inactive'}>About Us</Link>
            <Link to="/feature" className={activeClass === '/feature' ? 'activeMenu' : 'inactive'}>Features</Link>
            <Link to="/pricing" className={activeClass === '/pricing' ? 'activeMenu' : 'inactive'}>Pricing</Link>
            <Link to="/contactus" className={activeClass === '/contactus' ? 'activeMenu' : 'inactive'}>Contact</Link>
          </Nav>
          <Nav>
            {/* <Link to="/" className='login-route'>Login</Link> */}
            {isLoggedIn ? (
                <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-basic">
                    <img style={{width: '28px', height: '28px'}} src={ProfileIcon} alt="profile" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/dashboard">Dashboard</Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Link to="/login" className='login-route'>Login</Link>
              )}
            <Button className="demo-btn"
            // onClick={() => window.location.href = Constant.CALENDLY_URL}
            >Schedule a Demo</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </header>
    </div>
  );
}

export default Header;
