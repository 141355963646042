import React from 'react';
import { Modal } from 'react-bootstrap';
import './modal.scss';
import Button from '../Button/Button';

interface ModalProps {
  show: boolean;
  onHide: () => void;
  onResend: () => void;
  email: string;
}

const CustomModal: React.FC<ModalProps> = ({
  show,
  onHide,
  onResend,
  email,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body className="text-center">
        <div className="modal-icon">
          <i className="icon-envelope"></i>
        </div>
        <h3 className="modal-title">Reset Email Sent</h3>
        <p className="modal-text">
          We have just sent an email with a password reset link to <strong>{email}</strong>
        </p>
        <div className="modal-actions">
          <div className='send-again'>
          <Button button='Send Again' onClick={onResend}/>
          </div>
          <Button button='Got it' onClick={onHide} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
