import React from 'react';
import FeatureFrame from '../../assets/images/featureFrame.png';
import './featureList.scss';
import { Container } from 'react-bootstrap';

const FeatureList: React.FC = () => {

    return (
        <Container>
            <h2 className="heading">Full Form Of S.M.A.R.T.</h2>
        <div className='smart-text-section'>
            <div className='feature-list'>
                <div className="smart-text-list">
                    <div className='list-section'>
                        <div className='title-style'>Secure:-</div>
                        <p>Ensures data integrity with state-of-the-art encryption, safeguarding information from breaches & unauthorized access.</p>
                    </div>
                    <div className='list-section'>
                        <div className='title-style'>Monitored:-</div>
                        <p>Includes real-time tracking features to ensure optimal performance and immediate response to potential issues.</p>
                    </div>
                    <div className='list-section'>
                        <div className='title-style'>Automated:-</div>
                        <p>Streamlines repetitive tasks through automation, reducing manual effort and increasing overall productivity & efficiency.</p>
                    </div>
                    <div className='list-section'>
                        <div className='title-style'>Real-Time:-</div>
                        <p>Offers immediate data processing and feedback, enabling timely decision-making and action.</p>
                    </div>
                    <div className='list-section'>
                        <div className='title-style'>Transparent:-</div>
                        <p>Offers clear and open operations, ensuring users understand how data is managed and utilized.</p>
                    </div>
                </div>
            </div>
            <div className='feature-img'>
                <img src={FeatureFrame} style={{ width: '75%', }} />
            </div>
        </div>
        </Container>
    );
}
export default FeatureList;
