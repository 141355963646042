export interface Feature {
  feature_name: string;
  is_available: boolean;
  _id: string;
}

export interface Plan {
  _id: string;
  product_id: string;
  plan_type: "monthly" | "yearly";
  plan_name: "STANDARD" | "PROFESSIONAL" | "ENTERPRISES";
  price: number;
  features: Feature[];
  currency: string;
  interval: number;
  status: "active";
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export const staticPlanData: Plan[] = [
  {
    _id: "6716a8086d9477dacf5cea07",
    product_id: "plan_PBncb5BfDm9gj7",
    plan_type: "monthly",
    plan_name: "STANDARD",
    price: 89,
    features: [
      {
        feature_name: "24/7 Data Protection",
        is_available: true,
        _id: "6716a8086d9477dacf5cea08"
      },
      {
        feature_name: "Proactive Alerts",
        is_available: false,
        _id: "6716a8086d9477dacf5cea09"
      },
      {
        feature_name: "Scheduled Data Backups",
        is_available: true,
        _id: "6716a8086d9477dacf5cea0a"
      },
      {
        feature_name: "Full Backup",
        is_available: true,
        _id: "6716a8086d9477dacf5cea0b"
      },
      {
        feature_name: "Incremental Backups",
        is_available: true,
        _id: "6716a8086d9477dacf5cea0c"
      },
      {
        feature_name: "Multi-Location Data Replication",
        is_available: false,
        _id: "6716a8086d9477dacf5cea0d"
      },
      {
        feature_name: "User-Friendly Interface",
        is_available: true,
        _id: "6716a8086d9477dacf5cea0e"
      },
      {
        feature_name: "Dashboard Access",
        is_available: false,
        _id: "6716a8086d9477dacf5cea0f"
      },
      {
        feature_name: "Drag and Drop Functionality",
        is_available: false,
        _id: "6716a8086d9477dacf5cea10"
      },
      {
        feature_name: "Role Based Access Control",
        is_available: false,
        _id: "6716a8086d9477dacf5cea11"
      },
      {
        feature_name: "Comprehensive Analytics Tools",
        is_available: false,
        _id: "6716a8086d9477dacf5cea12"
      },
      {
        feature_name: "Customizable Dashboards",
        is_available: false,
        _id: "6716a8086d9477dacf5cea13"
      },
      {
        feature_name: "On Premises Storage",
        is_available: true,
        _id: "6716a8086d9477dacf5cea14"
      },
      {
        feature_name: "USB Port Lockdown",
        is_available: false,
        _id: "6716a8086d9477dacf5cea15"
      },
      {
        feature_name: "Instant Hard Drive Hide",
        is_available: false,
        _id: "6716a8086d9477dacf5cea16"
      },
      {
        feature_name: "Hybrid Storage option",
        is_available: false,
        _id: "6716a8086d9477dacf5cea17"
      },
      {
        feature_name: "Audit Trail Logs",
        is_available: false,
        _id: "6716a8086d9477dacf5cea18"
      },
      {
        feature_name: "Fast Data Retrieval",
        is_available: true,
        _id: "6716a8086d9477dacf5cea19"
      },
      {
        feature_name: "Free Ongoing Software Updates",
        is_available: true,
        _id: "6716a8086d9477dacf5cea1a"
      }
    ],
    currency: "INR",
    interval: 1,
    status: "active",
    createdAt: "2024-10-21T19:14:16.384Z",
    updatedAt: "2024-10-21T19:14:16.384Z",
    __v: 0
  },
  {
    _id: "6716a82c6d9477dacf5cea1e",
    product_id: "plan_PBndF0IltT07gN",
    plan_type: "monthly",
    plan_name: "PROFESSIONAL",
    price: 109,
    features: [
      {
        feature_name: "24/7 Data Protection",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea1f"
      },
      {
        feature_name: "Proactive Alerts",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea20"
      },
      {
        feature_name: "Scheduled Data Backups",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea21"
      },
      {
        feature_name: "Full Backup",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea22"
      },
      {
        feature_name: "Incremental Backups",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea23"
      },
      {
        feature_name: "Multi-Location Data Replication",
        is_available: false,
        _id: "6716a82c6d9477dacf5cea24"
      },
      {
        feature_name: "User-Friendly Interface",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea25"
      },
      {
        feature_name: "Dashboard Access",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea26"
      },
      {
        feature_name: "Drag and Drop Functionality",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea27"
      },
      {
        feature_name: "Role Based Access Control",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea28"
      },
      {
        feature_name: "Comprehensive Analytics Tools",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea29"
      },
      {
        feature_name: "Customizable Dashboards",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea2a"
      },
      {
        feature_name: "On Premises Storage",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea2b"
      },
      {
        feature_name: "USB Port Lockdown",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea2c"
      },
      {
        feature_name: "Instant Hard Drive Hide",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea2d"
      },
      {
        feature_name: "Hybrid Storage option",
        is_available: false,
        _id: "6716a82c6d9477dacf5cea2e"
      },
      {
        feature_name: "Audit Trail Logs",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea2f"
      },
      {
        feature_name: "Fast Data Retrieval",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea30"
      },
      {
        feature_name: "Free Ongoing Software Updates",
        is_available: true,
        _id: "6716a82c6d9477dacf5cea31"
      }
    ],
    currency: "INR",
    interval: 1,
    status: "active",
    createdAt: "2024-10-21T19:14:52.964Z",
    updatedAt: "2024-10-21T19:14:52.964Z",
    __v: 0
  },
  {
    _id: "6716a8b16d9477dacf5cea5e",
    product_id: "plan_PBnfYn7GheozXH",
    plan_type: "monthly",
    plan_name: "ENTERPRISES",
    price: 149,
    features: [
      {
        feature_name: "24/7 Data Protection",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea5f"
      },
      {
        feature_name: "Proactive Alerts",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea60"
      },
      {
        feature_name: "Scheduled Data Backups",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea61"
      },
      {
        feature_name: "Full Backup",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea62"
      },
      {
        feature_name: "Incremental Backups",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea63"
      },
      {
        feature_name: "Multi-Location Data Replication",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea64"
      },
      {
        feature_name: "User-Friendly Interface",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea65"
      },
      {
        feature_name: "Dashboard Access",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea66"
      },
      {
        feature_name: "Drag and Drop Functionality",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea67"
      },
      {
        feature_name: "Role Based Access Control",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea68"
      },
      {
        feature_name: "Comprehensive Analytics Tools",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea69"
      },
      {
        feature_name: "Customizable Dashboards",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea6a"
      },
      {
        feature_name: "On Premises Storage",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea6b"
      },
      {
        feature_name: "USB Port Lockdown",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea6c"
      },
      {
        feature_name: "Instant Hard Drive Hide",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea6d"
      },
      {
        feature_name: "Hybrid Storage option",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea6e"
      },
      {
        feature_name: "Audit Trail Logs",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea6f"
      },
      {
        feature_name: "Fast Data Retrieval",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea70"
      },
      {
        feature_name: "Free Ongoing Software Updates",
        is_available: true,
        _id: "6716a8b16d9477dacf5cea71"
      }
    ],
    currency: "INR",
    interval: 1,
    status: "active",
    createdAt: "2024-10-21T19:17:05.099Z",
    updatedAt: "2024-10-21T19:17:05.099Z",
    __v: 0
  },
  {
    _id: "6717caf87809d5dde6ebac88",
    product_id: "plan_PC8lrStegqS3FI",
    plan_type: "yearly",
    plan_name: "ENTERPRISES",
    price: 1788,
    features: [
      {
        feature_name: "24/7 Data Protection",
        is_available: true,
        _id: "6717caf87809d5dde6ebac89"
      },
      {
        feature_name: "Proactive Alerts",
        is_available: true,
        _id: "6717caf87809d5dde6ebac8a"
      },
      {
        feature_name: "Scheduled Data Backups",
        is_available: true,
        _id: "6717caf87809d5dde6ebac8b"
      },
      {
        feature_name: "Full Backup",
        is_available: true,
        _id: "6717caf87809d5dde6ebac8c"
      },
      {
        feature_name: "Incremental Backups",
        is_available: true,
        _id: "6717caf87809d5dde6ebac8d"
      },
      {
        feature_name: "Multi-Location Data Replication",
        is_available: true,
        _id: "6717caf87809d5dde6ebac8e"
      },
      {
        feature_name: "User-Friendly Interface",
        is_available: true,
        _id: "6717caf87809d5dde6ebac8f"
      },
      {
        feature_name: "Dashboard Access",
        is_available: true,
        _id: "6717caf87809d5dde6ebac90"
      },
      {
        feature_name: "Drag and Drop Functionality",
        is_available: true,
        _id: "6717caf87809d5dde6ebac91"
      },
      {
        feature_name: "Role Based Access Control",
        is_available: true,
        _id: "6717caf87809d5dde6ebac92"
      },
      {
        feature_name: "Comprehensive Analytics Tools",
        is_available: true,
        _id: "6717caf87809d5dde6ebac93"
      },
      {
        feature_name: "Customizable Dashboards",
        is_available: true,
        _id: "6717caf87809d5dde6ebac94"
      },
      {
        feature_name: "On Premises Storage",
        is_available: true,
        _id: "6717caf87809d5dde6ebac95"
      },
      {
        feature_name: "USB Port Lockdown",
        is_available: true,
        _id: "6717caf87809d5dde6ebac96"
      },
      {
        feature_name: "Instant Hard Drive Hide",
        is_available: true,
        _id: "6717caf87809d5dde6ebac97"
      },
      {
        feature_name: "Hybrid Storage option",
        is_available: true,
        _id: "6717caf87809d5dde6ebac98"
      },
      {
        feature_name: "Audit Trail Logs",
        is_available: true,
        _id: "6717caf87809d5dde6ebac99"
      },
      {
        feature_name: "Fast Data Retrieval",
        is_available: true,
        _id: "6717caf87809d5dde6ebac9a"
      },
      {
        feature_name: "Free Ongoing Software Updates",
        is_available: true,
        _id: "6717caf87809d5dde6ebac9b"
      }
    ],
    currency: "INR",
    interval: 1,
    status: "active",
    createdAt: "2024-10-22T15:57:36.583Z",
    updatedAt: "2024-10-22T15:57:36.583Z",
    __v: 0
  },
  {
    _id: "6717cb3b7809d5dde6ebac9d",
    product_id: "plan_PC8n2q1BNRJOkH",
    plan_type: "yearly",
    plan_name: "PROFESSIONAL",
    price: 1308,
    features: [
      {
        feature_name: "24/7 Data Protection",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebac9e"
      },
      {
        feature_name: "Proactive Alerts",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebac9f"
      },
      {
        feature_name: "Scheduled Data Backups",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebaca0"
      },
      {
        feature_name: "Full Backup",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebaca1"
      },
      {
        feature_name: "Incremental Backups",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebaca2"
      },
      {
        feature_name: "Multi-Location Data Replication",
        is_available: false,
        _id: "6717cb3b7809d5dde6ebaca3"
      },
      {
        feature_name: "User-Friendly Interface",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebaca4"
      },
      {
        feature_name: "Dashboard Access",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebaca5"
      },
      {
        feature_name: "Drag and Drop Functionality",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebaca6"
      },
      {
        feature_name: "Role Based Access Control",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebaca7"
      },
      {
        feature_name: "Comprehensive Analytics Tools",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebaca8"
      },
      {
        feature_name: "Customizable Dashboards",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebaca9"
      },
      {
        feature_name: "On Premises Storage",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebacaa"
      },
      {
        feature_name: "USB Port Lockdown",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebacab"
      },
      {
        feature_name: "Instant Hard Drive Hide",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebacac"
      },
      {
        feature_name: "Hybrid Storage option",
        is_available: false,
        _id: "6717cb3b7809d5dde6ebacad"
      },
      {
        feature_name: "Audit Trail Logs",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebacae"
      },
      {
        feature_name: "Fast Data Retrieval",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebacaf"
      },
      {
        feature_name: "Free Ongoing Software Updates",
        is_available: true,
        _id: "6717cb3b7809d5dde6ebacb0"
      }
    ],
    currency: "INR",
    interval: 1,
    status: "active",
    createdAt: "2024-10-22T15:56:43.783Z",
    updatedAt: "2024-10-22T15:56:43.783Z",
    __v: 0
  },
  {
    _id: "6717cb747809d5dde6ebacb2",
    product_id: "plan_PC8o2O2CKhYTWl",
    plan_type: "yearly",
    plan_name: "STANDARD",
    price: 1068,
    features: [
      {
        feature_name: "24/7 Data Protection",
        is_available: true,
        _id: "6717cb747809d5dde6ebacb3"
      },
      {
        feature_name: "Proactive Alerts",
        is_available: false,
        _id: "6717cb747809d5dde6ebacb4"
      },
      {
        feature_name: "Scheduled Data Backups",
        is_available: true,
        _id: "6717cb747809d5dde6ebacb5"
      },
      {
        feature_name: "Full Backup",
        is_available: true,
        _id: "6717cb747809d5dde6ebacb6"
      },
      {
        feature_name: "Incremental Backups",
        is_available: true,
        _id: "6717cb747809d5dde6ebacb7"
      },
      {
        feature_name: "Multi-Location Data Replication",
        is_available: false,
        _id: "6717cb747809d5dde6ebacb8"
      },
      {
        feature_name: "User-Friendly Interface",
        is_available: true,
        _id: "6717cb747809d5dde6ebacb9"
      },
      {
        feature_name: "Dashboard Access",
        is_available: false,
        _id: "6717cb747809d5dde6ebacba"
      },
      {
        feature_name: "Drag and Drop Functionality",
        is_available: false,
        _id: "6717cb747809d5dde6ebacbb"
      },
      {
        feature_name: "Role Based Access Control",
        is_available: false,
        _id: "6717cb747809d5dde6ebacbc"
      },
      {
        feature_name: "Comprehensive Analytics Tools",
        is_available: false,
        _id: "6717cb747809d5dde6ebacbd"
      },
      {
        feature_name: "Customizable Dashboards",
        is_available: false,
        _id: "6717cb747809d5dde6ebacbe"
      },
      {
        feature_name: "On Premises Storage",
        is_available: true,
        _id: "6717cb747809d5dde6ebacbf"
      },
      {
        feature_name: "USB Port Lockdown",
        is_available: false,
        _id: "6717cb747809d5dde6ebacc0"
      },
      {
        feature_name: "Instant Hard Drive Hide",
        is_available: false,
        _id: "6717cb747809d5dde6ebacc1"
      },
      {
        feature_name: "Hybrid Storage option",
        is_available: false,
        _id: "6717cb747809d5dde6ebacc2"
      },
      {
        feature_name: "Audit Trail Logs",
        is_available: false,
        _id: "6717cb747809d5dde6ebacc3"
      },
      {
        feature_name: "Fast Data Retrieval",
        is_available: true,
        _id: "6717cb747809d5dde6ebacc4"
      },
      {
        feature_name: "Free Ongoing Software Updates",
        is_available: true,
        _id: "6717cb747809d5dde6ebacc5"
      }
    ],
    currency: "INR",
    interval: 1,
    status: "active",
    createdAt: "2024-10-22T15:55:40.185Z",
    updatedAt: "2024-10-22T15:55:40.185Z",
    __v: 0
  }
];
