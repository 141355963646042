export const apiEndPoints = {
  LOGIN_PATH: "login",
  SIGN_UP_PATH: "signup",
  FORGOT_PASSWORD_PATH: "forget-password",
  RESET_PASSWORD_PATH: "reset-password",
  FRONT_IMAGE: "front-image",
  CONTACT_US_PATH: "contact-us",
  GET_PLANS: "plan?isAdminSide=false",
  CREATE_SUBSCRIPTION: "subscription",
};
