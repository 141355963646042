import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import HookForm from '../../components/HookForm/hookForm';
import TextField from '../../components/TextField/TextField'; // Import your TextField component
import './contactUs.scss'; // Import your CSS
import Button from '../../components/Button/Button';
import ContactImg from '../../assets/images/contactus.png';
import Constant from '../../util/constant';
import { Col, Container, Row } from 'react-bootstrap';
import { useAppDispatch } from '../../app/hook';
import { fetchFrontImage } from '../../features/auth/fetchFrontImageSlice';
import { AppDispatch } from '../../app/store';
import { OK } from '../../config/httpStatusCodes';
import { useNavigate } from 'react-router-dom';
import { contactUsData } from '../../features/auth/contactUsSlice';
import { showSuccess } from '../../helpers/messageHelper';

// Define the form inputs interface
interface IFormInput {
    name: string;
    phone: string;
    email: string;
    subject?: string;
    message: string;
}

const ContactUs: React.FC = () => {

    const [frontImage, setFrontImage] = useState<string>('');

    console.log(frontImage);
  
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
  
    useEffect(() => {
        const fetchImage = async () => {
            const fetchImageAction = fetchFrontImage('contact_us');
            const { payload } = await (dispatch as AppDispatch)(fetchImageAction);
            if(payload.status === OK) {
                setFrontImage(payload.data.responseData.image_url);
            }
        };
  
        // fetchImage();
    }, [dispatch]);


    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
    } = useForm<IFormInput>();

    // const defaultFormValues = {
    //     name: 'dhruvin',
    //     phoneNo: '+9898776765',
    //     email: 'dhruvin@gmail.com',
    //     subject: 'test',
    //     message: 'test message',
    // };

    const addEditForm = {
        name: {
            name: "name",
            validate: {
                required: {
                    value: true,
                },
                pattern: {
                    value: Constant.REGEX.NAME,
                    message: "Please enter valid name",
                },
            },
        },
        phoneNo: {
            name: "phoneNo",
            validate: {
                required: {
                    value: true,
                },
                pattern: {
                    value: Constant.REGEX.NUMBER,
                    message: "Please enter valid phone",
                },
            },
        },
        email: {
            name: "email",
            validate: {
                required: {
                    value: true,
                },
                pattern: {
                    value: Constant.REGEX.EMAIL,
                    message: "Please enter a valid email",
                },
            },
        },
        subject: {
            name: "subject",
        },
        message: {
            name: "message",
            validate: {
                required: {
                    value: false,
                },
                message: "Please enter a message",
            },
        },
    }
    const onSubmit = async(data: any) => {
        // console.log('Form Data:', data);
        
        // const ContactUsAction = contactUsData(data);
        // const { payload } = await (dispatch as AppDispatch)(ContactUsAction);

        // console.log("payload data ::::", payload);
        // if (payload && payload.status === OK) {
        //     const { responseMessage } = payload.data;
        //     console.log("data ::::", data);
        //       showSuccess(responseMessage);
        //       navigate('/home');
                
        //   }
    };
    const imageHeaderStyle = {
        backgroundImage: `url(${frontImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '425px',
        width: '100%',
    };
    return (
        <div>
           <div className="image-header">
        {/* <img src={frontImage} alt="about" className='responsive-image' /> */}
        <img src={ContactImg} alt="about" className='responsive-image' />
      </div>
            <Container>
                <section>
                    <Row className="text-center mb-3">
                        <Col>
                            <h2 className="privacy-title">Get In Touch With Us</h2>
                            <p className="intro-text-contact">
                                For More Information About Our Product & Services. Please Feel Free To Drop Us An Email.<br />
                                Our Staff Always Be There To Help You Out. Do Not Hesitate!
                            </p>
                        </Col>
                    </Row>
                </section>

                <div className="contactus-container">
                    <div className="contactus-details">
                        <div className='icon-div'>
                            <i className="icon icon-location" />
                        <div className="info-block">
                            <h6>Address:</h6>
                            <p>
                                S1, Makhanpur Roorkee, Near HDFC Bank Bhagwanpur, Haridwar
                                Uttarakhand - 24766, India
                            </p>
                        </div>
                        </div>
                        <div className='icon-div'>
                            <i className="icon icon-bxs_phone" />
                        <div className="info-block">
                            <h6>Phone:</h6>
                            <p>
                                Mobile: +91 92582 99518
                            </p>
                        </div>
                        </div>
                        <div className='icon-div'>
                            <i className="icon icon-clock" />
                        <div className="info-block">
                            <h6>Working Time:</h6>
                            <p>
                                Monday-Saturday: 9:00 AM - 6:00 PM <br />
                                Sunday: Closed <br />
                                Support: 24/7
                            </p>
                        </div>
                        </div>
                    </div>

                    {/* Right side: Contact form */}
                    <div className="contactus-form">
                        <HookForm
                            // defaultForm={defaultFormValues}
                            onSubmit={(data) => onSubmit(data)}
                        >
                            {(formMethod) => {
                                return (
                                    <>
                                        <div className="form-style">
                                            <div className="form-row">
                                                <div className='text-field'>
                                                    <TextField
                                                        label={'Your Name'}
                                                        formMethod={formMethod}
                                                        rules={addEditForm.name.validate}
                                                        name={addEditForm.name.name}
                                                        errors={formMethod?.formState.errors}
                                                        type="text"
                                                        placeholder="Abc"
                                                    />
                                                </div>
                                                <div className='text-field'>
                                                    <TextField
                                                        label={'Phone No.'}
                                                        formMethod={formMethod}
                                                        rules={addEditForm.phoneNo.validate}
                                                        name={addEditForm.phoneNo.name}
                                                        errors={formMethod?.formState.errors}
                                                        type="text"
                                                        placeholder="+91 9876 789 789"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <TextField
                                                    label={'Email address'}
                                                    formMethod={formMethod}
                                                    rules={addEditForm.email.validate}
                                                    name={addEditForm.email.name}
                                                    errors={formMethod?.formState.errors}
                                                    type="email"
                                                    placeholder="abcd@def.com"
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    label={'Subject'}
                                                    formMethod={formMethod}
                                                    rules={addEditForm.subject}
                                                    name={addEditForm.subject.name}
                                                    errors={formMethod?.formState.errors}
                                                    type="text"
                                                    placeholder="This is an optional"
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    label={'Message'}
                                                    formMethod={formMethod}
                                                    rules={addEditForm.message.validate}
                                                    name={addEditForm.message.name}
                                                    errors={formMethod?.formState.errors}
                                                    type="text"
                                                    placeholder="Hi! i'd like to ask about"
                                                    textarea={"textarea"}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex mt-2'>
                                            <Button
                                                button="Submit"
                                                type="submit"
                                                disabled={!formMethod?.formState.isValid}
                                                isLoading={false}
                                            />
                                        </div>
                                    </>
                                );
                            }}
                        </HookForm>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ContactUs;
