import React, { useState } from 'react';
import LoginImg from '../../assets/images/login.png';
import './setPassword.scss';
import { Card, Container } from 'react-bootstrap';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import HookForm from '../../components/HookForm/hookForm';
import Constant from '../../util/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { OK } from '../../config/httpStatusCodes';
import { useAppDispatch } from '../../app/hook';
import { AppDispatch } from '../../app/store';
import { resetPasswordData } from '../../features/auth/resetPasswordSlice';
import { showSuccess } from '../../helpers/messageHelper';

const SetPassword: React.FC = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const imageHeaderStyle = {
        backgroundImage: `url(${LoginImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '425px',
        width: '100%',
    };

    const { token } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    console.log("token ::::", token);

    const loginForm = {
        new_password: {
            name: "new_password",
            validate: {
                required: {
                    value: true,
                },
            },
        },
        confirm_password: {
            name: "confirm_password",
            validate: {
                required: {
                    value: true,
                },
                validate: (value: string, formValues: any) =>
                    value === formValues.new_password || "Both passwords must match",
            },
        },
    };

    const multiErrorFields = [
        { length: 'Minimum 8 characters' },
        { hasSpecialChar: 'At least 1 special character' },
        { hasNumbers: 'At least 1 numeric character' },
        { hasUppercase: 'At least 1 uppercase character' },
        { hasLowerCase: 'At least 1 lowercase character' },
    ];

    const onSubmit = async (data: any) => {
        console.log('Form Data:', data);
        const formData = {
            reset_password_token: token,
            new_password: data.new_password,
            confirm_password: data.confirm_password
        }
        const { payload } = await (dispatch as AppDispatch)(resetPasswordData(formData));
        if (payload && payload.status === OK) {
            console.log("payload ::::", payload);
            showSuccess(payload.data.responseMessage);
        }
        navigate("/");
    };

    return (
        <>
            <div className="image-header">
                <img src={LoginImg} alt="about" className='responsive-image' />
            </div>
            <Container>
                <h2 className="login-heading">New Password</h2>
                <p className="intro-text mb-4">To get started, you need to sign in here.</p>
                <div>
                    <HookForm
                        defaultForm={{}}
                        onSubmit={(data) => onSubmit(data)}
                    >
                        {(formMethod) => {
                            return (
                                <>
                                    <div className="form-style login-div">
                                        <Card>
                                            <div>
                                                <TextField
                                                    label={'Password'}
                                                    formMethod={formMethod}
                                                    rules={loginForm.new_password.validate}
                                                    name={loginForm.new_password.name}
                                                    errors={formMethod?.formState.errors}
                                                    multiErrorFields={multiErrorFields}
                                                    type="password"
                                                    placeholder="**********"
                                                />
                                                <span className="eye-icon" onClick={togglePasswordVisibility}>
                                                    {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                                                </span>
                                            </div>
                                            <div>
                                                <TextField
                                                    label={'Confirm Password'}
                                                    formMethod={formMethod}
                                                    rules={loginForm.confirm_password.validate}
                                                    name={loginForm.confirm_password.name}
                                                    errors={formMethod?.formState.errors}
                                                    type="password"
                                                    placeholder="**********"
                                                />
                                                <span className="eye-icon" onClick={toggleConfirmPasswordVisibility}>
                                                    {showConfirmPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                                                </span>
                                            </div>

                                            <Button
                                                button="Reset Password"
                                                type="submit"
                                                disabled={!formMethod?.formState.isValid}
                                                isLoading={false}
                                            />
                                        </Card>
                                    </div>
                                </>
                            );
                        }}
                    </HookForm>
                </div>
            </Container>
        </>
    );
};

export default SetPassword;
