import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authPostApi } from "../../services/api";
import { apiEndPoints } from "../../config/path";
import { showError } from "../../helpers/messageHelper";

type UserData = {
  uuid: string;
  full_name: string;
  email: string;
  isd_code: string;
  mobile_number: string;
  mobile_verified_at: string;
  is_profile_completed: boolean;
  screen_completed: number;
  type: number;
  current_type: number;
};

type ResponseData = {
  user?: UserData;
  is_already_logged_in?: boolean;
  access_token?: string;
  refresh_token?: string;
};

type SignUpDataState = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  responseCode: number | null;
  responseData: ResponseData;
  token: string;
  isFromBooking: boolean;
  uuid: string;
  isReviewWrite: boolean;
  isBookMarking: boolean;
};

const initialState: SignUpDataState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  responseCode: 0,
  responseData: {},
  token: "",
  isFromBooking: false,
  uuid: "",
  isReviewWrite: false,
  isBookMarking: false,
};

export type SignUpDataValues = {
  full_name: string;
  email: string;
  password: string;
};

export const signUpData = createAsyncThunk(
  "/signUpData",
  async (values: SignUpDataValues) => {
    try {
      const valuesData = values;
      console.log("valuesData 9999::::", valuesData);
      const payload = await authPostApi(apiEndPoints.SIGN_UP_PATH, valuesData);
      console.log("payload data 9999::::", payload);
      return payload;
    } catch (e: any) {
      console.log("error 9999::::", e.response.data.responseMessage);
      showError(e.response.data.responseMessage
      );
    }
  }
);
export const signUpDataSlice = createSlice({
  name: "signUpData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signUpData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signUpData.fulfilled, (state, { payload }) => {
        console.log("payload data 92255999::::", payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.responseCode = payload && payload?.status;
        state.responseData = payload && payload?.data?.data;
      })
      .addCase(signUpData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
  },
});


export const signUpDataReducer = signUpDataSlice.reducer;
