import React, { useEffect } from 'react';
import LoginImg from '../../assets/images/login.png';
import './signUp.scss';
import { Card, Container } from 'react-bootstrap';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import HookForm from '../../components/HookForm/hookForm';
import Constant from '../../util/constant';
import { useAppDispatch } from '../../app/hook';
import { useNavigate } from 'react-router-dom';
import { signUpData, SignUpDataValues } from '../../features/auth/signUpSlice';
import { AppDispatch } from '../../app/store';
import { OK } from '../../config/httpStatusCodes';
import { showSuccess } from '../../helpers/messageHelper';
import { setSession } from '../../config/localStorage';

const SignUp: React.FC = () => {

    const defaultFormValues = {
        full_name: "Destination",
        email: "dhruvin@gmail.com",
        password: "Test@12",
        confirm_password: "Test@12"
    };
    const imageHeaderStyle = {
        backgroundImage: `url(${LoginImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '425px',
        width: '100%',
    };

    const loginForm = {
        full_name: {
            name: "full_name",
            validate: {
                required: {
                    value: true,
                },
                pattern: {
                    value: Constant.REGEX.NAME,
                    message: "Please enter valid name",
                },
            },
        },
        email: {
            name: "email",
            validate: {
                required: {
                    value: true,
                },
                pattern: {
                    value: Constant.REGEX.EMAIL,
                    message: "Please enter valid email",
                },
            },
        },
        password: {
            name: "password",
            validate: {
                required: {
                    value: true,
                },
            },
        },
        confirm_password: {
            name: "confirm_password",
            validate: {
                required: {
                    value: true,
                },
                validate: (value: string, formValues: any) => 
                    value === formValues.password || "Both passwords must match",
            },
        },
    };

    // const [remember, setRemember] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onSubmit = async(data: any) => {
        // console.log('Form Data:', data);
        // const { confirm_password, ...rest } = data;
        
        // const SignUpAction = signUpData(rest);
        // const { payload } = await (dispatch as AppDispatch)(SignUpAction);

        // console.log("payload data ::::", payload);
        // if (payload && payload.status === OK) {
        //     const { responseMessage } = payload.data;
        //     console.log("data ::::", data);
        //       showSuccess(responseMessage);
        //       navigate('/');
                
        //   }
    };

    return (
        <>
             <div className="image-header">
                <img src={LoginImg} alt="about" className='responsive-image' />
            </div>
            <Container>
                <h2 className="login-heading">Sign Up</h2>
                <p className="intro-text mb-4">To get started, you need to sign up here.</p>
                <div>
                    <HookForm
                        // defaultForm={defaultFormValues}
                        onSubmit={(data) => onSubmit(data)}
                    >
                        {(formMethod) => {
                            return (
                                <>
                                    <div className="form-style login-div">
                                        <Card>
                                            <div>
                                                <TextField
                                                    label={'Full Name'}
                                                    formMethod={formMethod}
                                                    rules={loginForm.full_name.validate}
                                                    name={loginForm.full_name.name}
                                                    errors={formMethod?.formState.errors}
                                                    type="text"
                                                    placeholder="John Doe"
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    label={'Email address'}
                                                    formMethod={formMethod}
                                                    rules={loginForm.email.validate}
                                                    name={loginForm.email.name}
                                                    errors={formMethod?.formState.errors}
                                                    type="email"
                                                    placeholder="i.e. john@mail.com"
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    label={'Password'}
                                                    formMethod={formMethod}
                                                    rules={loginForm.password.validate}
                                                    name={loginForm.password.name}
                                                    errors={formMethod?.formState.errors}
                                                    type="password"
                                                    placeholder="**********"
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    label={'Confirm Password'}
                                                    formMethod={formMethod}
                                                    rules={loginForm.confirm_password.validate}
                                                    name={loginForm.confirm_password.name}
                                                    errors={formMethod?.formState.errors}
                                                    type="password"
                                                    placeholder="**********"
                                                />
                                            </div>
                                            <Button
                                                button="Register"
                                                type="submit"
                                                isLoading={false}
                                            />
                                        </Card>
                                    </div>
                                </>
                            );
                        }}
                    </HookForm>
                    <div className="create-acc">
            <p>
            Already have an account?{" "}
              <a onClick={() => navigate("/login")} href="#">
                Login
              </a>
            </p>
          </div>
                </div>
            </Container>
        </>
    );
};

export default SignUp;
