import React from 'react';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import logo from '../../assets/images/Footer.svg';
import './Footer.scss';
import { Link } from 'react-router-dom';
const Footer: React.FC = () => {
  return (
    <footer style={{ backgroundColor: '#000', color: '#fff', padding: '20px 0' }}>
      <Container>
        <div className='main-footer'>
          <div className="mb-4">
            <div className='textfield-div'>
              <img src={logo} alt="Smart Data Manager" style={{ width: '90%' }} />
            </div>
          </div>
          <div className="text-div">
            <h5>Useful Links</h5>
            <ul className='link-div'>
              <li><Link to="/" className='link-txt'>Home</Link></li>
              <li><Link to="/about" className='link-txt'>About</Link></li>
              <li><Link to="/feature" className='link-txt'>Features</Link></li>
              <li><Link to="/pricing" className='link-txt'>Pricing</Link></li>
              <li><Link to="/contactus" className='link-txt'>Contact</Link></li>
            </ul>
          </div>
          <div className="text-div">
            <h5>Legal</h5>
            <ul className='link-div'>
              <li><Link to="/privacy-policy" className='link-txt'>Disclaimer</Link></li>
              <li><Link to="/privacy-policy" className='link-txt'>Refund Policy</Link></li>
              <li><Link to="/privacy-policy" className='link-txt'>Software Licence Agreement</Link></li>
              <li><Link to="/privacy-policy" className='link-txt'>Service Level Agreement</Link></li>
            </ul>
          </div>
        </div>
        {/* Footer Bottom Section */}
        <Row>
          <Col className='text-start copy-right'>
            <p>© 2024 Smart Data Manager. Copyright and rights reserved</p>
          </Col>
          <Col md={6} className="footer-end">
            <a href="#terms" className='text-terms'>Terms and Conditions</a>
            <span style={{ margin: '0 10px' }}>•</span>
            <Link to="/privacy-policy" className='text-terms'>Privacy Policy</Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
