import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/layout";
import "./App.scss";
import { publicRoutes } from "./routes";
import ToastNotification from "./components/common/ToastNotification";
import { useApiCaller } from "./hooks/useApiCaller";

function App(props: any) {
  // useApiCaller();

  return (
    
    <React.Fragment>
      <Router>
      <Routes>
        {publicRoutes.map((route: any, idx: number) => (
          <Route
            key={idx}
            element={<Layout />}
          >
            <Route key={idx} path={route.path} element={route.element} />
          </Route>
        ))}
      </Routes>
      </Router>
      <ToastNotification />
    </React.Fragment>
  );
}

export default App;
