import React, { useState } from 'react';
import LoginImg from '../../assets/images/login.png';
import './forgotPassword.scss';
import { Card, Container, FormCheck } from 'react-bootstrap';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import HookForm from '../../components/HookForm/hookForm';
import Constant from '../../util/constant';
import { Link, useNavigate } from 'react-router-dom';
import CustomModal from '../../components/Modal/modal';
import { forgotPasswordData } from '../../features/auth/forgotPasswordSlice';
import { useAppDispatch } from '../../app/hook';
import { AppDispatch } from '../../app/store';
import { OK } from '../../config/httpStatusCodes';
import { showSuccess } from '../../helpers/messageHelper';

const ForgotPassword: React.FC = () => {


    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [userEmail, setUserEmail] = useState(""); // New state to store the email
  
    const handleSendEmail = (email: string) => {
        setUserEmail(email); // Store the email
        setModalVisible(true);
    };
  
    const handleResendEmail = () => {
        // Use the stored email
        onSubmit({email: userEmail});
        
        // Implement your resend logic here
    };
  
    const handleCloseModal = () => {
      setModalVisible(false);
      navigate('/');
    };
  
    const imageHeaderStyle = {
        backgroundImage: `url(${LoginImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '425px',
        width: '100%',
    };

    const loginForm = {
        email: {
            name: "email",
            validate: {
                required: {
                    value: true,
                },
                pattern: {
                    value: Constant.REGEX.EMAIL,
                    message: "Please enter valid email",
                },
            },
        },
    }


    const dispatch = useAppDispatch();
   

    const onSubmit = async(data: any) => {
        // handleSendEmail(data.email);
        
        
        // const ForgotPasswordAction = forgotPasswordData(data);
        // const { payload } = await (dispatch as AppDispatch)(ForgotPasswordAction);

        
        // if (payload && payload.status === OK) {
        //     const { responseMessage } = payload.data;
        //       showSuccess(responseMessage);     
        //   }
    };

    return (
        <>
        <div className="image-header">
                <img src={LoginImg} alt="about" className='responsive-image' />
            </div>
            <Container>
                <h2 className="login-heading">Forget Password</h2>
                <p className="intro-text mb-4">Enter your email and we’ll send you instructions to reset password</p>
                <div>
                    <HookForm
                        onSubmit={(data) => onSubmit(data)}
                    >
                        {(formMethod) => {
                            return (
                                <>
                                    <div className="form-style login-div">
                                        <Card>
                                        <div>
                                            <TextField
                                                label={'Email address'}
                                                formMethod={formMethod}
                                                rules={loginForm.email.validate}
                                                name={loginForm.email.name}
                                                errors={formMethod?.formState.errors}
                                                type="email"
                                                placeholder="i.e. john@mail.com"
                                            />
                                        </div>
                                        <Button
                                            button="Register"
                                            type="submit"
                                            disabled={!formMethod?.formState.isValid}
                                            isLoading={false}
                                        />
                                        </Card>
                                    </div>
                                </>
                            );
                        }}
                    </HookForm>
                    <div className='login-back'>
                        <Link to='/'>Back to Login</Link>
                    </div>
                    <CustomModal
            show={modalVisible}
            onHide={handleCloseModal}
            onResend={handleResendEmail}
            email={userEmail}
          />
                </div>
            </Container>
        </>
    );
}
export default ForgotPassword;
