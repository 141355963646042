import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import PricingImg from '../../assets/images/pricing.png';
import './pricing.scss';
import { useAppDispatch } from '../../app/hook';
import { fetchFrontImage } from '../../features/auth/fetchFrontImageSlice';
import { OK } from '../../config/httpStatusCodes';
import { AppDispatch } from '../../app/store';
import { getPlans } from '../../features/subscription/getPlanSlice';
import { Plan } from '../../components/PricingPlan/pricingPlan';
import { useNavigate } from 'react-router-dom';
import Constant from '../../util/constant';
import { staticPlanData } from '../../dummy/planRes';

const PricingTable = () => {

    const [frontImage, setFrontImage] = useState<string>('');
    const [plans, setPlans] = useState<Plan[]>([]);
    const [isYearly, setIsYearly] = useState<boolean>(false);
    console.log("isYearly:::", isYearly);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const toggleBilling = (yearly: boolean) => {
        setIsYearly(yearly);
    };
    const filteredPlans = staticPlanData
    .filter((plan) => plan.plan_type === (isYearly ? 'yearly' : 'monthly'))
    .sort((a, b) => a.price - b.price);
    console.log(frontImage);

    const dispatch = useAppDispatch();

    const fetchImage = async () => {
        const fetchImageAction = fetchFrontImage('pricing');
        const { payload } = await (dispatch as AppDispatch)(fetchImageAction);
        if (payload.status === OK) {
            setFrontImage(payload.data.responseData.image_url);
        }
    };

    const fetchPlans = async () => {
        const fetchPlansAction = getPlans();
        const { payload } = await (dispatch as AppDispatch)(fetchPlansAction);
        if (payload.status === OK) {
            setPlans(payload.data.responseData);
            console.log(payload.data.responseData);
        }
    };
    const handleContactUs = () => {
        navigate('/contactus');
        toggleModal();
    };


    useEffect(() => {
        // fetchImage();
        // fetchPlans();
    }, [dispatch]);

    const imageHeaderStyle = {
        backgroundImage: `url(${PricingImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '425px',
        width: '100%',
    };
    return (
        <>
            <div className="image-header">
                {/* <img src={frontImage} alt="about" className='responsive-image' /> */}
                <img src={PricingImg} alt="about" className='responsive-image' />
            </div>
            <Container fluid className="mt-1 pricing-container">
                <div className='width-div'>
                    <div className="billing-toggle">
                        <span className={`bill-plan ${!isYearly ? 'billSelected' : ''}`}>Monthly</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                id="custom-switch"
                                checked={isYearly}
                                onChange={(e) => toggleBilling(e.target.checked)}
                            />
                            <span className="slider round"></span>
                        </label>
                        <span className={`bill-plan ${isYearly ? 'billSelected' : ''}`}>Yearly</span>
                    </div>
                </div>
            <button className="sticky-button" onClick={toggleModal}>
            <span className="icon-left-arrow"></span>
            </button>
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className='mb-0'>What's next</h6>
                            <span className="icon-cross" onClick={toggleModal}></span>
                        </div>
                        <div className="divider"></div>
                        <div className="modal-body">
                            <ul>
                                <div className='d-flex align-items-center'>
                                    <span className="icon-right-arrow"></span>  
                                    <li
                                    //  onClick={() => window.location.href = Constant.CALENDLY_URL}
                                     >Request A Demo</li>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className="icon-right-arrow"></span>
                                    <li onClick={handleContactUs}>Contact Us</li>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className="icon-right-arrow"></span>
                                    <li>Start 14-Day Free Trial</li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
                <div className="pricing-table-wrapper">
                    <Table responsive bordered className="pricing-table">
                        <thead>
                            <tr>
                                <th>
                                    <div className="feature-header">
                                        Features<br />
                                        <span>`Price (Per User/{isYearly ? 'Year' : 'Month'})`</span>
                                    </div>
                                </th>
                                {filteredPlans.map((plan, index) => (
                                    <th key={index}>
                                        <div className="price-header">
                                            <div className="price">Rs.{plan.price} <span>/Plus GST</span></div>
                                            <Button className="button-warning">Choose this plan</Button>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPlans[0]?.features.map((feature, index) => (
                                <tr key={index}>
                                    <td>{feature.feature_name}</td>
                                    {filteredPlans.map((plan, planIndex) => (
                                        <td key={planIndex}>
                                            {plan.features[index].is_available && <span className="icon-right"></span>}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Container>
        </>
    );
};

export default PricingTable;
