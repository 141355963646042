import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FeatureImg from '../../assets/images/feature.png';
import FeatureFrame from '../../assets/images/featureFrame.png';
import './feature.scss';
import FeatureList from '../../components/featureList/featureList';
import Guarantees from '../../components/Guarantees/guarantees';
import Benefits from '../../components/Benefits/benefits';
import { useAppDispatch } from '../../app/hook';
import { fetchFrontImage } from '../../features/auth/fetchFrontImageSlice';
import { AppDispatch } from '../../app/store';
import { OK } from '../../config/httpStatusCodes';

const Feature: React.FC = () => {

    const [frontImage, setFrontImage] = useState<string>('');

    console.log(frontImage);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchImage = async () => {
            const fetchImageAction = fetchFrontImage('features');
            const { payload } = await (dispatch as AppDispatch)(fetchImageAction);
            if (payload.status === OK) {
                setFrontImage(payload.data.responseData.image_url);
            }
        };

        // fetchImage();
    }, [dispatch]);

    const imageHeaderStyle = {
        backgroundImage: `url(${frontImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '425px',
        width: '100%',
    };
    return (
        <div className="feature-page">
            <div className="image-header">
                {/* <img src={frontImage} alt="about" className='responsive-image' /> */}
                <img src={FeatureImg} alt="about" className='responsive-image' />
            </div>
            {/* <div style={imageHeaderStyle}></div> */}
            <section>
                <FeatureList />
            </section>
            <section>
                <Guarantees />
            </section>
            <section className="benefits-section">
                <Benefits />
            </section>
        </div>
    );
}
export default Feature;
