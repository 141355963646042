import React from 'react';
import About from '../../assets/images/aboutUs.png';
import './smartData.scss';
import { Container } from 'react-bootstrap';

const SmartData: React.FC = () => {
    const dataList: any[] = [
        { title: 'User Interface' },
        { title: 'Customization' },
        { title: 'Support' },
        { title: 'Setup' },
        { title: 'Pricing' },
        { title: 'Scalability' },
        { title: 'Updates' },
        { title: 'Energy Efficiency' },
        { title: 'Data Recovery' },
        { title: 'Security' },
        { title: 'Analytics Tools' },
        { title: 'Access from Anywhere' },
    ];

    return (
        <div className='smart-bg'>
        <div className='container'>
        <div className='smart-data-div'>
            <div>
                <h2 className="about-title-main beta">Why Choose Smart<br/> Data Manager </h2>
                <div className='list-style'>
                    {dataList.map((item) => (
                        <div className='list-div'>
                            <span className="rightIcon icon-Subtract"></span>
                            <div className='list-text'>{item.title}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <img src={About} alt="about" />
            </div>
        </div>
        </div>
        </div>
    );
}
export default SmartData;
