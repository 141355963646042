import React, { useState } from 'react';
import PlanCard from '../PlanCard/planCard';
import './pricingPlan.scss';
import { features } from 'process';

// Define the type for individual plan
interface Feature {
    feature_name: string;
    is_available: boolean;
    _id: string;
}

interface PricingPlansProps {
    plans: Plan[];
    onSelectPlan: (planId: string) => void;
}

export interface Plan {
    _id: string;
    product_id: string;
    plan_type: string;
    plan_name: string;
    price: number;
    features: Feature[];
    currency: string;
    interval: number;
    status: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

const PricingPlans: React.FC<PricingPlansProps> = ({ plans, onSelectPlan }) => {
    const [selectedPlan, setSelectedPlan] = useState<string>('PROFESSIONAL');
    const [isYearly, setIsYearly] = useState<boolean>(false);
    console.log("isYearly:::", isYearly);

    // filters plans based on isYearly 
    const filteredPlans = plans
    .filter((plan) => plan.plan_type === (isYearly ? 'yearly' : 'monthly'))
    .sort((a, b) => a.price - b.price);
    // console.log("filteredPlans:::", filteredPlans);


    const handlePlanSelect = (planName: string, planId: string) => {
        setSelectedPlan(planName);
    };

    const handleButtonClick = (planId: string) => {
        onSelectPlan(planId);
    };

    const toggleBilling = (yearly: boolean) => {
        setIsYearly(yearly);
    };

    return (
        <div className="pricing-container">
            <h2 className="title-plan">Choose Plan That's <br /> Right For You</h2>
            <p className='plan-text'>Choose plan that works best for you, feel free to contact us</p>
            <div className="billing-toggle d-flex align-items-center justify-content-center">
                {/* <span className={`bill ${!isYearly ? 'billSelected' : ''}`}>Bill Monthly</span>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="billingToggle"
                        checked={isYearly}
                        onChange={(e) => toggleBilling(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="billingToggle" style={{ display: 'none' }}>
                        Toggle billing period
                    </label>
                </div>
                <span className={`bill ${isYearly ? 'billSelected' : ''}`}>Bill Yearly</span> */}
                <div className="billing-toggle">
                    <button
                        className={!isYearly ? 'active' : ''}
                        onClick={() => toggleBilling(false)}
                    >
                        Bill Monthly
                    </button>
                    <button
                        className={isYearly ? 'active' : ''}
                        onClick={() => toggleBilling(true)}
                    >
                        Bill Yearly
                    </button>
                </div>
            </div>
            <div className="plans-container">
                {filteredPlans.map((plan, index) => (
                    <PlanCard
                        key={plan.product_id}
                        name={plan.plan_name}
                        buttonText="Select Plan"
                        // price={plan.price}
                        price={plan.price}
                        features={plan.features.slice(0, 5).map((feature) => feature.feature_name)}
                        isSelected={selectedPlan === plan.plan_name}
                        onSelect={() => handlePlanSelect(plan.plan_name, plan.product_id)}
                        onButtonClick={() => handleButtonClick(plan.product_id)}
                    />
                ))}
            </div>
        </div>
    );
};

export default PricingPlans;
