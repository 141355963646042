import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './benefits.scss';
import triangleImage from '../../assets/images/triangle.png'; // Adjust the path as needed

// Define a type for the data items
interface Benefits {
    icon: string;
    title: string;
    description: string;
}

const Benefits: React.FC = () => {
    // Define the data array with proper types
    const benifit: Benefits[] = [
        { icon: 'white-right', title: "24/7 Data Protection", description: "Continuous protection to keep your data secure." },
        { icon: 'lock', title: "Robust Security", description: "Prevent unauthorized access with our advanced security protocols." },
        { icon: 'data-recovery', title: "Fast Data Recovery", description: "Quick and efficient data recovery processes." },
        { icon: 'solutions', title: "Customizable Solutions", description: "Tailor-made features to meet specific industry needs." },
        { icon: 'globe-grid', title: "Global Availability", description: "Access support and services worldwide." },
        { icon: 'renewable-energy', title: "Energy Efficiency", description: "More energy-efficient than other solutions." },
        { icon: 'growth-graph', title: "Advanced Analytics Tools", description: "Gain insights with advanced analytics to make informed decisions." },
        { icon: 'update', title: "Free Software Updates", description: "Keep your system up-to-date withno additional cost." },
        { icon: 'customer-support', title: "Customer Support", description: "24/7 expert support via phone, email, and chat." },
    ];

    return (
        <div className="bg-round">
            <div className='bg-db'>
                <Container>
                    <h2 className="benifit-heading">Benefits</h2>
                    <div className="row">
                        {benifit.map((benefit, index) => (
                            <div key={index} className="col-md-4 mb-4 arrow-row">
                                <div className="benefit-card-style d-flex flex-column">
                                    <div className="icon-container-benifit mb-3">
                                        <div className='icon-bg'>
                                            <span className={`icon-benefit icon-${benefit.icon}`}></span>
                                        </div>
                                    </div>
                                    <h5 className="gurantee-title">{benefit.title}</h5>
                                    <p className="gurantee-description">{benefit.description}</p>
                                </div>
                                {(index + 1) % 3 !== 0 && <div className="vertical-line-benifit"></div>}
                                {index < 6 && <div className="horizontal-line-benifit"></div>}
                                {index < 6 && (index + 1) % 3 !== 0 && (
                                    <img src={triangleImage} alt="Triangle" className="triangle-image-benifit" />
                                )}
                            </div>

                        ))}
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default Benefits;
