import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PrivacyPolicyImg from '../../assets/images/Hero.png';
import './privacyPolicy.scss';
import { useAppDispatch } from '../../app/hook';
import { fetchFrontImage } from '../../features/auth/fetchFrontImageSlice';
import { AppDispatch } from '../../app/store';
import { OK } from '../../config/httpStatusCodes';

const PrivacyPolicy: React.FC = () => {

  const [frontImage, setFrontImage] = useState<string>('');

  console.log(frontImage);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchImage = async () => {
      const fetchImageAction = fetchFrontImage('privacy_policy');
      const { payload } = await (dispatch as AppDispatch)(fetchImageAction);
      if (payload.status === OK) {
        setFrontImage(payload.data.responseData.image_url);
      }
    };

    fetchImage();
  }, [dispatch]);

  const imageHeaderStyle = {
    backgroundImage: `url(${frontImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '425px',
    width: '100%',
  };
  return (
    <div className="privacy-policy-section">
      <div className="image-header">
        {/* <img src={frontImage} alt="about" className='responsive-image' /> */}
        <img src={PrivacyPolicyImg} alt="about" className='responsive-image' />
      </div>

      <div className='container ctn'>
        <Row className="text-center mb-5">
          <Col>
            <h2 className="privacy-title">Privacy Policy</h2>
            <p className="intro-text">
              At Smart Data Manager, a product of Wizone IT Network India
              Pvt Ltd, we prioritize the privacy of our users and are
              committed to complying with applicable data protection laws in
              India. This Privacy Policy outlines how we collect, use, disclose,
              and protect your information when you use our website, smartdatamanager.in,
              or engage with our services.
            </p>
          </Col>
        </Row>

        <Row className="style-margin">
          <Col>
            <h3>Information We Collect</h3>
            <p>
              <strong>Personal Information:</strong>  In compliance with the Companies Act,
              2013, and other applicable laws, we may collect personal information such as your name,
              email address, phone number, billing information, and other identifiers necessary for service.
            </p>
            <p>
              <strong>Usage Data:</strong> We collect data on how our website
              and services are accessed and used, including your IP address,
              browser type, and activity on our website. This information helps
              us improve our services and enhance user experience.
            </p>
          </Col>
        </Row>

        <Row className="style-margin">
          <Col>
            <h3>How We Use Your Information</h3>
            <p>
              <strong>Your information may be used to:</strong> Provide, maintain, and improve
              our services; Process transactions and manage your account;
              Comply with legal obligations under the Information Technology
              Act, 2000, and the Companies Act, 2013; Communicate with you about
              updates, promotions, and support; Ensure adherence to regulatory
              requirements and company policies.
            </p>
          </Col>
        </Row>

        <Row className="style-margin">
          <Col>
            <h3>Disclosure of Your Information</h3>
            <p>
              <strong> We value your privacy and ensure that your personal data is not
                sold or rented to third parties. However, your information may be
                disclosed under the following circumstances:</strong>
            </p>
            <p>
              <strong>Service Providers:</strong> To trusted third parties who
              assist us in operating our website and services, subject to
              strict confidentiality obligations.
            </p>
            <p>

              <strong>Legal Compliance:</strong> In compliance with Indian
              laws, including the Companies Act, 2013, and the Information
              Technology Act, 2000, we may disclose your information if
              required by law to protect our legal rights.
            </p>
          </Col>
        </Row>

        <Row className="style-margin">
          <Col>
            <h3>Data Security</h3>
            <p>
              We implement robust security measures, including encryption and
              access controls, to protect your data from unauthorized access
              and disclosure. However, please note that no method of
              transmission over the internet is 100% secure.
            </p>
          </Col>
        </Row>

        <Row className="style-margin">
          <Col>
            <h3>Cookies and Tracking Technologies</h3>
            <p>
              Our website uses cookies and similar technologies to enhance your browsing experience,
              personalize content, and analyze website traffic. By using our website, you consent to the use of cookies in accordance with our Cookie Policy.
            </p>
          </Col>
        </Row>

        <Row className="style-margin">
          <Col>
            <h3>Data Retention</h3>
            <p>
              We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected,
              including compliance with legal, accounting, or reporting requirements.
            </p>
          </Col>
        </Row>

        <Row className="style-margin">
          <Col>
            <h3>Your Rights</h3>
            <p>
              In accordance with Indian data protection laws, you have the right to:
            </p>
            <ul>
              <li>Access your personal information</li>
              <li>Request that your personal information be corrected or  </li>
              <li>Request correction of inaccurate data</li>
              <li>Request deletion of your data (subject to legal constraints)</li>
              <li>Object to or restrict processing of your data</li>
              <li>Opt-out of promotional communications by following the unsubscribe link in our emails</li>
            </ul>
          </Col>
        </Row>


        <Row className="style-margin">
          <Col>
            <h3>Compliance with Indian Laws</h3>
            <p>
              This Privacy Policy is governed by the laws of India, including the Companies Act, 2013, the Information
              Technology Act, 2000, and other relevant regulations. We are committed to adhering to these laws to
              protect your privacy and ensure data security.
            </p>
          </Col>
        </Row>

        <Row className="style-margin">
          <Col>
            <h3>Changes to This Privacy Policy</h3>
            <p>
              We may update this Privacy Policy periodically to reflect changes
              in our practices or Indian regulatory requirements. Any changes will be posted on this page,
              and your continued use of our website and services will constitute your acceptance of the revised policy.
            </p>
          </Col>
        </Row>

        <Row className="style-margin">
          <Col>
            <h3>Contact Us</h3>
            <p>
              For any questions, concerns, or requests related to this Privacy Policy or our data handling practices, please contact us at:
              <ul className='contact-list'>
                <li>Wizone IT Network India Pvt Ltd</li>
                <li>Email: legal@smartdatamanager.in</li>
                <li>Address: F5, Anand Vihar Colony, Bhagwanpur, Haridwar, 247661.</li>
              </ul>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
